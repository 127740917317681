import React from "react";

import { graphql, useStaticQuery } from "gatsby";

import PropTypes from "prop-types";

const TeamPicture = ({ pictureName, fullName }) => {
  const { images } = useStaticQuery(graphql`
    query Images {
      images: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { regex: "/^team/" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fixed {
                src
                srcSet
              }
            }
          }
        }
      }
    }
  `);

  const teamPics = images.edges.map(({ node }) => ({
    base: node.base,
    src: node.childImageSharp.fixed.src,
  }));

  const getPicture = (name) => teamPics.find(({ base }) => base === name)?.src;

  return (
    <img
      src={getPicture(pictureName)}
      alt={fullName}
      className="team-member-picture"
    />
  );
};

export default TeamPicture;

TeamPicture.propTypes = {
  fullName: PropTypes.string.isRequired,
  pictureName: PropTypes.string.isRequired,
};
