const team = {
  founders: [
    {
      fullName: "Tarik Dadi",
      position: "CEO",
      description:
        "Applied Mathematics Graduate from Dauphine University & Agro ParisTech. Former Senior Data Scientist at AXA and BPCE.",
      pictureName: "tarik-dadi.jpg",
      linkedin: "https://www.linkedin.com/in/tarikdadi",
    },
    {
      fullName: "Hadrien De March",
      position: "CTO",
      description:
        "PhD from Ecole Polytechnique, recognized expert in Optimal Transport Theory with numerous published research papers. Former Quant at Goldman Sachs.",
      pictureName: "hadrien-de-march.jpg",
      linkedin: "https://www.linkedin.com/in/hadrien-de-march-957a2749/",
    },
  ],
  advisors: [
    {
      fullName: "Anne-Sophie Carrese",
      position: "Member of the Board of Directors",
      pictureName: "anne-sophie.jpg",
      linkedin: "https://www.linkedin.com/in/anne-sophie-carrese-566990a6/",
    },
    {
      fullName: "Stéphane Pesque",
      position: "Member of the Board of Directors",
      pictureName: "stephane-pesque.png",
      linkedin: "https://www.linkedin.com/in/spesque/",
    },
    {
      fullName: "Michel De Lempdes",
      position: "Member of the Board of Directors",
      pictureName: "michel-de-lempdes.png",
      linkedin: "https://www.linkedin.com/in/michel-de-lempdes-55568180/",
    },
    {
      fullName: "Gwenael Moy",
      position: "Member of the Board of Directors",
      pictureName: "gwenael-moy.jpg",
      linkedin: "https://www.linkedin.com/in/gwenaelmoy/",
    },
    {
      fullName: "Jonathan Gray",
      position: "Advisor",
      pictureName: "jonathan-gray.jpg",
      linkedin: "https://www.linkedin.com/in/jonathan-gray-78033320/",
    },
    {
      fullName: "Gary Bolger",
      position: "Medical Advisor",
      pictureName: "gary-bolger.jpg",
      linkedin: "https://www.linkedin.com/in/gary-bolger-6a943849/",
    },
  ],
};

export default team;
