import styled, { css } from "styled-components";

export const TeamSectionWrapper = styled.div`
  background-image: linear-gradient(180deg, #e5e5e5, #fff);
  display: flex;
  flex-direction: column;
  gap: 3em;

  :nth-child(2n) {
    background-image: linear-gradient(184.31deg, #00033f 2.8%, #1b1c54 97.32%);

    h4 {
      color: #fff;
    }
  }

  ${(props) =>
    props.founders
      ? css`
          padding: 6vh calc(15vw / 2) calc(40px + 6vh) calc(15vw / 2);

          @media (min-width: 1190px) {
            padding: 6vh 5vw calc(40px + 6vh) 5vw;
          }
        `
      : css`
          padding: 6vh 5vw calc(40px + 6vh) 5vw;
        `}
`;

export const SectionTitle = styled.h4`
  flex: 1 1 45%;
  font-size: 40px;
  font-weight: 600;
  line-height: 44px;
  text-transform: none;
  text-align: center;

  @media (max-width: 390px) {
    font-size: 32px;
    line-height: 36px;
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  justify-content: center;
  gap: 3em;

  ${(props) =>
    props.founders
      ? css`
          grid-template-columns: 1fr;

          @media (max-width: 498px) {
            gap: 2em;
          }

          @media (min-width: 1190px) and (max-width: 1450px) {
            grid-template-columns: repeat(2, 1fr);
          }

          @media (min-width: 1451px) {
            grid-template-columns: repeat(2, 628px);
          }
        `
      : css`
          grid-template-columns: 256px;

          @media (min-width: 700px) and (max-width: 1023px) {
            grid-template-columns: repeat(2, 256px);
          }

          @media (min-width: 1024px) {
            grid-template-columns: repeat(3, 256px);
          }
        `}
`;
