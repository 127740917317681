import React from "react";

import { useTranslation } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";

import LinkedInLogo from "assets/icons/linkedin.svg";
import TeamPicture from "components/TeamPicture";

import {
  MemberWrapper,
  MemberImgWrapper,
  MemberImgHoverStyle,
  MemberDetail,
  MemberDetailLarge,
  MemberName,
} from "./styles";

const Member = ({
  fullName,
  position,
  description,
  pictureName,
  linkedin,
  founders,
}) => {
  const { t } = useTranslation();

  return (
    <MemberWrapper founders={founders}>
      <MemberImgWrapper tabIndex={0} founders={founders}>
        <TeamPicture pictureName={pictureName} fullName={fullName} />
        <MemberImgHoverStyle founders={founders}>
          {!founders && (
            <MemberDetail>
              <span>{t(position)}</span>
            </MemberDetail>
          )}
          {linkedin && (
            <div>
              <a
                href={linkedin}
                target="_blank"
                rel="noreferrer"
                alt={fullName}
              >
                <img src={LinkedInLogo} alt={`LinkedIn of ${fullName}`} />
              </a>
            </div>
          )}
        </MemberImgHoverStyle>
      </MemberImgWrapper>
      {!founders && <MemberName>{fullName}</MemberName>}
      {founders && (
        <MemberDetailLarge>
          <h3>{fullName}</h3>
          <h3 className="position">{position}</h3>
          <p>{t(description)}</p>
        </MemberDetailLarge>
      )}
    </MemberWrapper>
  );
};

Member.propTypes = {
  fullName: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  description: PropTypes.string,
  pictureName: PropTypes.string.isRequired,
  linkedin: PropTypes.string,
  founders: PropTypes.bool,
};

Member.defaultProps = {
  founders: false,
  description: null,
  linkedin: undefined,
};

export default Member;
