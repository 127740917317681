import React from "react";

import { graphql } from "gatsby";

import { useTranslation } from "gatsby-plugin-react-i18next";

import HalfHero from "components/HalfHero";
import Investors from "components/Investors";
import PageContainer from "components/PageContainer";
import SEO from "components/SEO";
import TeamSection from "components/TeamSection";

import team from "../../data/team";

const TeamPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <HalfHero
        title={t("Ambitious problem solvers transforming the real-world")}
      />
      <TeamSection title={t("Meet the founders")} founders>
        {team.founders.map(
          ({ fullName, position, description, pictureName, linkedin }) => (
            <TeamSection.Member
              key={`founder-${fullName}`}
              fullName={fullName}
              position={position}
              description={description}
              pictureName={pictureName}
              linkedin={linkedin}
            />
          )
        )}
      </TeamSection>
      <TeamSection title={t("Advisors & Board")}>
        {team.advisors.map(({ fullName, position, pictureName, linkedin }) => (
          <TeamSection.Member
            key={`advisor-${fullName}`}
            fullName={fullName}
            position={position}
            pictureName={pictureName}
            linkedin={linkedin}
          />
        ))}
      </TeamSection>
      <Investors />
    </PageContainer>
  );
};

export default TeamPage;

// eslint-disable-next-line react/prop-types
export const Head = ({ location: { pathname } }) => (
  <SEO title="Team" pathname={pathname} />
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["team", "common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
