import React from "react";

import { useTranslation } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";

import EfLogo from "assets/images/logo-ef.png";
import ElaiaLogo from "assets/images/logo-elaia.png";
import OmnesLogo from "assets/images/logo-omnes.png";
import RaiseLogo from "assets/images/logo-raise.png";
import {
  TeamSectionWrapper,
  SectionTitle,
} from "components/TeamSection/styles";

const ContentWrapper = styled.div`
  display: grid;
  justify-content: center;
  gap: 3em;

  @media (min-width: 390px) and (max-width: 578px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 579px) and (max-width: 1060px) {
    grid-template-columns: repeat(2, 212px);
  }

  @media (min-width: 1061px) {
    grid-template-columns: repeat(4, 212px);
  }
`;

const TeamSection = ({ title, children, founders }) => (
  <TeamSectionWrapper>
    <SectionTitle>{title}</SectionTitle>
    <ContentWrapper founders={founders}>{children}</ContentWrapper>
  </TeamSectionWrapper>
);

TeamSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  founders: PropTypes.bool,
};

TeamSection.defaultProps = {
  children: null,
  founders: false,
};

const BackerItemWrapperHyperlink = styled.a`
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 5vw;

  img {
    width: 45vw;
    filter: grayscale(100%);
  }

  :hover img {
    filter: grayscale(0);
  }

  @media (hover: none) and (pointer: coarse) {
    &:active img {
      filter: grayscale(0);
    }
  }

  @media (min-width: 550px) {
    img {
      width: 25vw;
    }
  }

  @media (min-width: 750px) {
    padding: 2vw;
  }
`;

const BackerItem = ({ name, link, logoSrc }) => (
  <BackerItemWrapperHyperlink
    href={link}
    alt={name}
    target="_blank"
    rel="noreferrer"
  >
    <img src={logoSrc} alt={name} />
  </BackerItemWrapperHyperlink>
);

BackerItem.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  logoSrc: PropTypes.string.isRequired,
};

const Investors = () => {
  const { t } = useTranslation();

  return (
    <TeamSection title={t("Our Investors")}>
      <BackerItem
        name="Elaia Partners"
        link="https://www.elaia.com/"
        logoSrc={ElaiaLogo}
      />
      <BackerItem
        name="Raise"
        link="https://www.raise.co/"
        logoSrc={RaiseLogo}
      />
      <BackerItem
        name="OMNES"
        link="https://www.omnescapital.com/"
        logoSrc={OmnesLogo}
      />
      <BackerItem
        name="Entrepreneur First"
        link="https://www.joinef.com/"
        logoSrc={EfLogo}
      />
    </TeamSection>
  );
};

export default Investors;
