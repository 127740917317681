import React from "react";

import PropTypes from "prop-types";

import Member from "components/TeamMember";

import { TeamSectionWrapper, SectionTitle, ContentWrapper } from "./styles";

const TeamSection = ({ title, children, founders }) => (
  <TeamSectionWrapper founders={founders}>
    <SectionTitle>{title}</SectionTitle>
    <ContentWrapper founders={founders}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { founders })
      )}
    </ContentWrapper>
  </TeamSectionWrapper>
);

TeamSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  founders: PropTypes.bool,
};

TeamSection.defaultProps = {
  children: null,
  founders: false,
};

TeamSection.Member = Member;

export default TeamSection;
