import styled, { css } from "styled-components";

export const MemberWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${(props) =>
    props.founders
      ? css`
          padding: 32px;
          gap: 2em;
          border-radius: 8px;
          background-color: #fff;
          box-shadow: 0px 6px 20px -4px rgba(16, 24, 40, 0.1);

          @media (min-width: 525px) and (max-width: 1189px),
            (min-width: 1281px) and (max-width: 1403px) {
            grid-template-columns: repeat(2, 1fr);
          }

          @media (min-width: 1190px) and (max-width: 1332px) {
            grid-template-columns: 1fr 262px;
          }

          @media (min-width: 1404px) {
            grid-template-columns: 256px 1fr;
          }
        `
      : css`
          gap: 1em;
        `}
`;

export const MemberImgHoverStyle = styled.div`
  height: 25%;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: auto;
  display: flex;
  justify-content: ${(props) =>
    props.founders ? "flex-end" : "space-between"};
  align-items: flex-end;
  padding: 5px 10px;
  border-radius: 0 0 8px 8px;

  background-color: transparent;
  background-image: linear-gradient(1deg, #00033f 9%, transparent 82%);

  transform: translateY(100%);
  transition: transform 500ms ease-in-out;

  a img {
    width: 20px !important;
    height: 22px;
    max-width: 20px;
    margin-bottom: 5px;
  }
`;

export const MemberImgWrapper = styled.div`
  position: relative;
  overflow: hidden;

  .team-member-picture {
    border-radius: 8px;
    height: ${(props) => (props.founders ? "100%" : "256px")};
    width: 100%;
    object-fit: cover;
  }

  &:hover {
    ${MemberImgHoverStyle} {
      transform: translateY(0);
    }
  }

  @media (hover: none) and (pointer: coarse) {
    &:focus {
      ${MemberImgHoverStyle} {
        transform: translateY(0);
      }
    }
  }

  ${(props) =>
    props.founders &&
    css`
      @media (min-width: 390px) and (max-width: 524px),
        (min-width: 740px) and (max-width: 2140px) {
        .team-member-picture {
          height: 256px !important;
        }
      }
    `}
`;

export const MemberDetailLarge = styled.div`
  h3 {
    margin: 0;
  }

  p {
    margin-bottom: 0;
    margin-top: 8px;
    font-size: 16px;
    line-height: 26px;
    color: #454552;
  }

  .position {
    font-size: 18px;
    color: #12ce9f;
  }

  @media (min-width: 900px) {
    h3 {
      margin-bottom: 4px;
    }
  }

  @media (max-width: 1445px) {
    p {
      font-size: 14px;
    }
  }
`;

export const MemberDetail = styled.div`
  span {
    font-size: 16px;
    line-height: 22px;
    font-weight: 200;
    text-transform: capitalize;
  }
`;

export const MemberName = styled.p`
  font-weight: 600;
  text-align: center;
`;
