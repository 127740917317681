import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import BGDotTexture from "assets/images/bg-dot-texture.svg";

const HalfHeroSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 3em 1.5em 1em;

  min-height: 25vh;

  background-image: url(${BGDotTexture}),
    linear-gradient(72deg, #1f2058 65%, #00033f);
  background-position: 0px 0px, 0px 0px;
  background-size: auto, auto;
`;

const HalfHeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 85vw;
  padding: 3em 0;

  h1 {
    margin: 0;
    font-size: 40px;
    line-height: 70px;
  }

  p {
    margin: 0;
    font-size: 18px;
    font-weight: 300;
  }

  a {
    font-family: Sinkinsans, sans-serif;
    color: #12ce9f;
  }

  @media (min-width: 750px) {
    flex-direction: row;
    align-items: center;

    h1 {
      font-size: 48px;
      line-height: 70px;
    }

    p {
      font-size: 20px;
    }
  }

  @media (min-width: 1024px) {
    p {
      max-width: 45ch;
    }

    h1 {
      font-size: 58px;
    }
  }
`;

const HalfHero = ({ title, subtitle, description }) => (
  <HalfHeroSection>
    <HalfHeroContent>
      <div>
        <h1>{title}</h1>
        {subtitle && <p>{subtitle}</p>}
      </div>
      {description && <p>{description}</p>}
    </HalfHeroContent>
  </HalfHeroSection>
);

HalfHero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string,
};

HalfHero.defaultProps = {
  subtitle: null,
  description: null,
};

export default HalfHero;
